<template>
    <div>
        <div class="content">
            <div class="qrcode" ref="qrCodeUrl"></div>
            <div class="code">
                核验码：{{code}}
            </div>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2';
    export default {
        name: 'contact',
        data() {
            return {
                code: '',
            }
        },
        mounted() {
            this.code = this.$route.params.code;
            this.creatQrCode();
        },
        methods: {
            creatQrCode() {
                new QRCode(this.$refs.qrCodeUrl, {
                    text: this.code, // 需要转换为二维码的内容
                    width: 200,
                    height: 200,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
        }
    }
</script>

<style scoped>
    .content {
        text-align: center;
        padding-top: 50px;
        margin-bottom: 50px;
    }

    .qrcode {
        display: inline-block;
    }

    .qrcode img {
        display: inline-block !important;
    }

    .code {
        margin-top: 20px;
        font-size: 30px;
        color: red;
        font-weight: 700;
    }
</style>